
.gplp-voting-modal p{font-size: 14px;}
.invest-opportunities{border-radius: 4px;color:#fff;padding:0px 30px 0px 0px;align-items: flex-end;display: table;position: relative;}
/* .invest-opportunities.coming{padding-top:75px} */
.invest-opportunities h3{font-size: 26px;}
.invest-opportunities .list {
    padding-top: 10px;
    display: flex;
    list-style: none;
    padding-left: 0;
    flex-direction: column;
    margin-bottom:10px;

  }
  .invest-opportunities .list  li{
 padding-bottom:15px;
      display: flex;
      color:rgba(255,255,255,.8);
    
   }
   .invest-opportunities .list li:last-child{padding-bottom: 0;}
   .invest-opportunities .list  li span {
    padding-left: 8px;
  }
  .invest-opportunities .list  li img{width:18px;height:18px;margin:0 !important;}
  .invest-opportunities .list  li, .invest-opportunities p,  .invest-opportunities  h4{
  color:rgba(255,255,255,.8);
       
      }
      .coming-soon{
        position: absolute;
        left: 0;
        top: 25px;
        padding: 10px;
        padding-left: 60px;
        border-radius: 4px;
        font-weight: 700;
        font-size: 14px;z-index: 1;}


        .invest-opportunities.yield-bg{ 
          background-repeat: no-repeat;
          background-size: contain;
      } 
      .invest-opportunities{padding:30px  30px } 
      .invest-opportunities .img-wrappper {min-height: 500px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position:left top;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
       }
      .invest-opportunities .img-wrappper img {margin:0;}
  @media (min-width:992px){
    .invest-opportunities{padding:0px 30px 0px 0px;}
    .invest-opportunities .spacer{padding:13px  30px} 
    .invest-opportunities{display: flex;align-items: center;}
    .mt-lg-3,.my-lg-3{margin-top:1rem!important}
    .mt-lg-0,.my-lg-0{margin-top:0!important}

  }
  @media (max-width:1199px){

    .invest-opportunities .img-wrappper {display: none;}
.invest-opportunities.coming{padding-top:75px}
  }
  @media (min-width: 1200px) and (max-width: 1399px){

    .invest-opportunities .img-wrappper {min-height:540px;
    
     }
     .invest-opportunities .spacer {
      padding: 10px 20px;
  }
  }
  @media (min-width:1400px){

    .invest-opportunities .img-wrappper {min-height:480px;
    
     }

  }

  .invest-opportunities-detail{max-width: 1140px;}
  .invest-opportunities-detail .v-line{border-right:1px solid #f3f4f5}
  .invest-opportunities-detail h3{font-size: 16px;}
  .invest-opportunities-detail .progress{height:46px;width:100%}
  .invest-opportunities-detail .progress-bar.fill {
    background-color:#182F3B;width: 100%;
}
.invest-opportunities-detail .progress-bar svg {
max-width: 14px;
}
.invest-opportunities-detail .progress-bar svg path {
fill:#fff
}
.invest-opportunities-detail .progress-bar img{margin:0;}
.invest-opportunities-detail .btn-green{background:#7AC968}

.invest-opportunities-detail .highlights{flex-wrap: wrap;border-radius: 4px; margin-left: -20px;}
.invest-opportunities-detail .highlights svg{width:32px;height:32px;margin-bottom: 5px;}
.invest-opportunities-detail .highlights svg .a{fill:#182f3b}
.invest-opportunities-detail .highlights li{font-weight: 700;text-align: center;width: calc(20% - 40px);border: 1px solid #E7E8F2;padding: 20px 10px;margin: 20px;display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;line-height: 16px;}
.invest-opportunities-detail .highlights li span{color:#5DB562;display: block;}

.invest-opportunities-detail .list ul{padding-left: 15px;}
.invest-opportunities-detail .list h3{margin-bottom: 25px;}
.invest-opportunities-detail .list ul li{margin-bottom: 15px;}
.invest-opportunities-detail .jambo_table.table-striped.fee-table th{background: #182F3B05;}
.invest-opportunities-detail .jambo_table.table-striped.fee-table td {background: #fff !important;padding: 10px;}
.invest-opportunities-detail .fee-detail h4{font-size: 14px;font-weight: 700;}
.invest-opportunities-detail .documents ul{ display: flex;flex-direction: row;width: 100%; flex-wrap: wrap;}
.invest-opportunities-detail .documents ul li {width: calc(20% - 0px);padding: 7px; margin-bottom: 15px;}
.invest-opportunities-detail .documents ul li .img-wrapper{width:auto;height:120px;background-size: cover;background-repeat: no-repeat;background-position: center;border: 1px solid #dcdcdc;}
.invest-opportunities-detail .documents ul li .img-wrapper + h3{min-height: 38px;}
.invest-opportunities-detail .documents.bio ul li .img-wrapper + h3{min-height: initial;}
.invest-opportunities-detail .documents.bio ul li p{font-size: 12px;}
.invest-opportunities-detail .documents ul li a{color:#182F3B;display: flex;align-items: center;font-size: 14px;}
.invest-opportunities-detail .documents ul li svg{margin-right: 10px;}
.invest-opportunities-detail .documents ul li a:hover svg path,.invest-opportunities-detail .documents ul li a:hover {
  fill: #5BB462;color: #5BB462;text-decoration: none;
}

 .bio-modal .modal-body {padding:25px;}
 .bio-modal img{max-width: 100%;}
 .bio-modal .close-modal {
  z-index: 1;
  right: 10px;
  top: 11px;
}
.bio-modal .close-modal button {
  border: 0;
  background: #000;
  color: #fff;
}
.invest-opportunities-detail .wildcat-footer{  background-image: linear-gradient(#7AC968, #5BB462);border-radius: 5px;padding: 20px;
  color: #fff;}
  .invest-opportunities-detail .wildcat-footer .left-sec {font-size: 22px;font-weight: 700;}
  .invest-opportunities-detail .wildcat-footer .left-sec svg {
    max-width: 26px;}
  .invest-opportunities-detail .wildcat-footer .left-sec svg,.invest-opportunities-detail .wildcat-footer .left-sec path {fill:#fff}
  .invest-opportunities-detail .wildcat-footer .left-sec span{padding-left: 10px;}
  .invest-opportunities-detail .wildcat-footer .rt-sec button,.invest-opportunities-detail .wildcat-footer .rt-sec button:hover,.invest-opportunities-detail .wildcat-footer .rt-sec button:focus{background-color: transparent !important;border: 1px solid #fff !important;box-shadow: none;}
@media (max-width:991px){
  .invest-opportunities-detail .highlights li{width: calc(25% - 40px)}
  .invest-opportunities-detail .documents ul li{width: calc(25% - 0px)}
}
@media (max-width:768px){

  .invest-opportunities-detail .documents ul li{width: calc(33.3333% - 0px)}
}
@media (max-width:500px){
  .invest-opportunities-detail .highlights li{width: calc(50% - 40px)}
  .invest-opportunities-detail .documents ul li{width: calc(50% - 0px)}
}
@media (min-width:1200px){
  .invest-opportunities-detail .progress{width: 70%;}
}

.content .table-wrapper h3{font-size: 18px;margin-bottom: 18px;}
.content .table-wrapper h4{font-size: 16px;}
.content .table {margin: 20px 0}
.content .table .img-wrapper{width:320px;padding:0;vertical-align: middle;}
.content .table img {width:100%;margin:0;}
.content .table td,.content .table th{border: 1px solid #E7E8F2;padding:20px;vertical-align: middle;}
.content .table td span{display: block;font-weight: bold;}
.content .table td {font-weight: normal;}

@media (max-width:991px) {
  #desktopImg {
    display: none;
  }
}


.lockIcon{
  align-self: center;
  -webkit-margin-start: 0.75rem;
  margin-inline-start: 0.75rem;
  border-radius: 13px;
  --tw-bg-opacity: 1;
  background-color: rgba(var(--color-gray-200),var(--tw-bg-opacity));
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  display: flex;
  width: 6rem;
  height:6rem;
  background-color: #D4EDD0;
}