.app-main--navbar-custom {
    background-color: #ffffff;
    color: #222222 !important;
   border-bottom:1px solid #ccc;
    border-radius: 0;
  
}

.app-main--navbar-custom .collapsing {
    padding: 0;
}

.app-main--navbar-custom .container-fluid {
    padding-right: 0;
    padding-left: 0;
}
.app-main--navbar-custom .navbar-nav > li  {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-left: 1px solid #E7E8F2;
    font-size: 14px;
   
}
.app-main--navbar-custom .navbar-nav > li > a {
    color: #222222 !important;
    font-size: 14px;
    padding: 0;
}

.app-main--navbar-custom .navbar-nav > .active > a {
    color: #222222 !important;
    background-color: transparent;
}

.app-main--navbar-custom .navbar-nav > li > a.white-text {
    color: #fff !important;
}

.app-main--navbar-custom .navbar-nav > .active > a.white-text {
    color: #fff !important;
    background-color: transparent;
}
.dropdown-toggle svg{color:#A7A9C0}
.app-main--navbar-custom .navbar-nav > li > a:hover,
.app-main--navbar-custom .navbar-nav > li > a:focus,
.app-main--navbar-custom .navbar-nav > .active > a:hover,
.app-main--navbar-custom .navbar-nav > .active > a:focus,
.app-main--navbar-custom .navbar-nav > .open > a {
    text-decoration: none;
    /* background-color:#5bb462;
    color: white !important; */
}

.app-main--navbar-custom .navbar-brand {
    padding-left: 10px;
    color: #222222 !important;
}

.app-main--navbar-custom .navbar-toggle {
    background-color: #eeeeee;
}

.app-main--navbar-custom .icon-bar {
    background-color: #5bb462;
}

.app-main--navbar-custom .navbar-collapse.collapse {
    padding: 0;
}
.active-page-title{color: #182F3B;
    font-size: 16px;
    font-weight: 700;
    height: 57px;
    line-height: 57px;
    padding-left: 10px;
    display: flex;
width:100%;
justify-content: center;
}
    @media(min-width:768px){.active-page-title{width: auto;justify-content:start;}}
.navbar-header .logo svg{max-width:198px;}
.logo-wrap {}

#app-main--header-mobile {height: 57px;line-height: 57px;}

span.dropdown.open,span.dropdown.show {
    /* line-height: initial; */
}
@media (max-width:475px) {
    .profileName{
        min-width: 110px;
        max-width:110px;
    }
    .modalWidth{
        width: 100% !important;
    }
}
.modalWidth{
    width: 40%;
}
.profile-pic{width:26px;height: 26px;
        background-color: #5bb462;
        border-radius: 100%;
        text-align: center;
        line-height:initial;
        color: #fff;
        font-weight: bold;
        margin-right: 8px;padding: 6px 0 5px 0;}