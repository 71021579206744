.highlight-container {
    align-items: center;
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    min-height: 250px;
    justify-content: space-between;
    margin: 15px auto;
    padding: 15px;
    width: 250px;
}

.highlight-title {
    font-size: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: uppercase;
}

.highlight-data {
    font-size: 13px;
}

.highlight-container-image-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.highlight-container-image-container:hover {
    background-color: rgba(81, 41, 101, .2);
}

.highlight-container-image-container.selected {
    background-color: rgba(81, 41, 101, .2);
    cursor: default;
    opacity: .6;
}

.highlight-container-image-container-image-size {
    height: 60px !important;
    width: auto;
}
