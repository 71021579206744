.floating-button-panel {
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, .5);
    box-sizing: border-box;
    display: none;
    padding: 10px;
    position: relative;
    top: 0;
    z-index: 1;
}

.nav-md .floating-button-panel {
    width: 85%;
}

.nav-sm .floating-button-panel {
    width: calc(100% - 100px);
}

@media (max-width: 991px) {
    .nav-md .floating-button-panel {
        width: 100%;
    }
}

.margin-top-10px {
    margin-top: 10px !important;
}

.margin-top-30px {
    margin-top: 30px !important;
}

.margin-top-60px {
    margin-top: 60px !important;
}

.heading-text {
    color: #5bb462;
    font-weight: 400;
}

.project-details-container {
    color: #000;
    display: flex;
    flex-direction: column;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.project-details-container-go-back {
    color: #5bb462;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: bold;
}

.project-details-container-project-image {
    grid-row: 1;
    grid-column: 12;
}

.project-details-container-project-name {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(12, 1fr);
}

.project-details-container-project-name h1, .edit-input {
    grid-column: 1 / 12;
    grid-row: 1;
}

.project-details-highlight {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 !important;
    margin: 0 !important;
}

.project-details-overview {
    grid-column: 1 / 13;
    grid-row: 6;
}

.project-details-overview img {
    height: auto !important;
    max-width: 100% !important;
}

.project-details-overview-data {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    min-height: 600px;
    padding: 20px;
}

.project-details-overview-data img {
	height: auto !important;
	max-width: 100% !important;
	margin: 5px 0;
}

.project-reg-cf-social-buttons {
    display: flex;
    font-size: 12px;
    justify-content: flex-end;
    padding: 20px;
}

.project-reg-cf-video-buttons {
    border-radius: 5px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.reg-cf-social-project-button-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 50px;
    justify-content: center;
    width: 50px;
}

.reg-cf-social-project-button {
    color: #5bb462;
    font-size: 33px;
}

.project-details-table-container {
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0, 0, 0, .5);
}

.project-details-table-container img {
    height: auto !important;
    max-width: 100% !important;
	margin: 5px 0;
}

@media (min-width: 1600px) {
    .nav-sm .project-details-container {
        padding: 30px 20px;
    }

    .nav-md .project-details-container {
        padding: 30px 20px;
    }
}

@media (max-width: 1600px) and (min-width: 991px) {
    .nav-sm .project-details-container {
        padding: 30px 20px;
    }

    .nav-md .project-details-container {
        padding: 30px 20px;
    }
}

@media (max-width: 990px) {
    .nav-sm .project-details-container {
        padding: 30px 20px;
    }

    .nav-md .project-details-container {
        padding: 30px 20px;
    }
}
