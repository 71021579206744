/* New User */
.new-user-dashboard {
    padding:50px 30px;
    margin: 0;
    background:#1a303c;;
	text-align:center;
    height: 100%;
    color:#fff;
}

.new-user-dashboard h1,.new-user-dashboard p{ color:#fff;}
.new-user-dashboard h1{
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 20px;}
.font-weight-bold {
    font-weight: 700!important;
}

.rounded-circle {
    border-radius: 50%!important;
}
.blue-bg{background:#4d7cfe;}
.green-bg{background:#6dd230;}
.orange-bg{background:#ffab2b;}
.blue-bg-light{background:#2ce5f6;}
.icon-wrap{padding:12px;color: #fff;border: 5px solid #e0e7f3; display: flex;}
.icon-wrap.dollar svg,.icon-wrap.roi svg{height:1.5rem;}
.icon-wrap svg{max-width: 16px;min-width: 12px;}
.tile-wrap{margin-bottom: 20px;}
.invest-dashboard .tile-wrap h2 {color: #bfbfbf;margin-top: 0;margin-bottom: 12px;font-size: 18px;font-weight:700;line-height: 22px;}
.btn-green{background:#5bb462;cursor: pointer;}
.text-white,.text-white:hover,.text-white:focus{color: #fff;}
.text-underline{text-decoration: underline;}
.apexcharts-toolbar{z-index: 1 !important;}

.w-100{width:100%}
.invest-dashboard .card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    color:#fff;
 padding: 0;
margin:10px 0 ;
 flex-direction: row;
 list-style: none;
 height:100px;overflow: auto;
}
.invest-dashboard .card h3{line-height: 22px;}
.invest-dashboard .card.header{height:auto;width:calc(100% - 10px)}
.invest-dashboard .card.header li{border:0}
.invest-dashboard .card::-webkit-scrollbar,.invest-dashboard .card-wrapper::-webkit-scrollbar,.invest-dashboard.earnings .list-funds::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .invest-dashboard .card::-webkit-scrollbar-track,  .invest-dashboard .card-wrapper::-webkit-scrollbar-track,.invest-dashboard.earnings .list-funds::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .invest-dashboard .card::-webkit-scrollbar-thumb,  .invest-dashboard .card-wrapper::-webkit-scrollbar-thumb,.invest-dashboard.earnings .list-funds::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .invest-dashboard .card::-webkit-scrollbar-thumb:hover,.invest-dashboard.earnings .list-funds::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
.invest-dashboard  .card li{padding-right: 15px;
    border-right: 1px solid #ccc;
    padding-left: 15px;}
    .invest-dashboard  .card li:last-child{border-right: none;}
    .invest-dashboard  .card li:first-child{padding-left:0;width:40%}
        .invest-dashboard  .card li:nth-child(2){width:30%}
        .invest-dashboard  .card li:nth-child(3){width:30%}
.files-card{ border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.55rem;}
.invest-dashboard .card.link{cursor: pointer;}
.invest-dashboard .card.link:hover{background: #5bb462;}
.invest-dashboard .card.link:hover p{color: #ffffff;}

.invest-dashboard {padding: 0;margin: 0;background: linear-gradient(to top, #fefefe 80%, #1a303c 20%);}

.invest-dashboard .wp-block-jetpack-layout-grid h2{font-size: 18px;}
.invest-dashboard h2,.invest-dashboard .prj-update h1{font-size: 28px;font-weight: 700;margin-bottom: 20px;}
.invest-dashboard h2+p{padding-bottom: 10px;color: #212529;}
.invest-dashboard h3{font-size: 16px;margin-bottom: 15px;}
.invest-dashboard .react-tabs__tab{color:#b4ffba;font-weight: bold;font-size: 14px;text-transform: uppercase;padding-bottom:2px;margin: 0 20px;
    margin-bottom: 4px;}
    .invest-dashboard .react-tabs__tab-list {
 margin: 10px 0;
    padding: 10px 20px;
    background:#5bb462;
    
   
}
.invest-dashboard .highlight{color: #551d71;text-decoration: underline;cursor: pointer;}
.invest-dashboard .react-tabs__tab--selected {
    background: transparent;
   border-color:transparent;
    color: white;
  border-radius:0; 
   border-bottom: 1px solid #fff !important;
}
.invest-dashboard .react-tabs__tab:focus {
    box-shadow: none;
    border-color: transparent;
    outline: none;
}
.invest-dashboard .react-tabs__tab:focus:after {
   
    height:0px;

}
.invest-dashboard .right_col{padding-left: 0 !important;padding-right: 0 !important;min-height: auto !important;}

.white-bg{background-color: #fff;}
.rounded-top{border-top-left-radius:5px;border-top-right-radius:5px;}

.jambo_table tbody tr:hover td{background-color: #fff; background: #fff !important;} 
table.jambo_table{border: 0 !important;}
.table>tbody>tr>td,.table>thead>tr>th{padding: 20px 20px;}
.dropdown.category-filter {
    background: #fff;
    z-index: 10;
}
.category-filter .btn{background-color: transparent;color: #000;border-radius:3px;margin: 0;font-size:13px;font-weight:bold;
    padding: 8px 8px 8px 8px;min-width: 174px;}
    .category-filter .btn:hover,.category-filter .btn:focus,  .category-filter .btn-primary{background-color: transparent !important;
        color: #000 !important;}
        .category-filter  .dropdown-menu{min-width:174px}
        .category-filter   .dropdown-menu>li>a {
            color: #1f1f1f;
            font-size: 14px;
            padding: 4px 8px;
        }
.category-filter .fa.pull-right {
    margin-left: 1.3em;margin-top:2px;
}
.filter-wrap{margin-top:0px;}
.w-9verify ul{margin-top:25px;}
.w-9verify h4{font-size: 16px;margin: 15px 0;}
.clearfix{clear: both;}
.cursor-pointer{cursor: pointer;}
/*Investor-modal */
.investor-modal{width:80%;margin:0 auto}

.investor-modal .left{padding-right:25px;}
.investor-modal .divider{margin: 0;border: 0;}
.investor-modal .divider::after{border-right: 1px solid #ccc;position: absolute;top:0;bottom: 0;right:0}
.investor-modal .right{padding-left:25px;}
.investor-modal .left h2{ font-size:26px;
    margin-bottom: 15px;}
    .investor-modal .right h2{font-size: 22px;
        margin-top: 42px;}
.investor-modal .modal-header {
    padding:0;
    border-bottom: 0px solid transparent;
}
.investor-modal .modal-content{border-radius: 0;}
.investor-modal .close {
   
    font-size: 50px;
    font-weight: normal;
   position: absolute;
    right: 0;
    z-index: 1000;
}



/* Earnings */
.cus-search{min-width: 300px;}
.cus-search input[type="text"]{box-shadow: none !important;height: 26px !important;}

.invest-dashboard .tile-wrap h5{font-weight: 600; line-height: 24px;}


@media (min-width: 768px){
    .modal-dialog {
  
        margin: 25px auto;
    }


}
@media (max-width: 768px){
    .investor-modal .left{padding-right:15px;}
    .investor-modal .right{padding-left:15px;}
}
@media (min-width: 992px){
.invest-dashboard .card {
 
 margin:30px 0 40px 0;

}

}




