.image-card-select-new-image {
    background-image: linear-gradient(to bottom, transparent 60%, rgba(0, 0, 0, .7));
    color: white;
    display: flex;
    flex-direction: column-reverse;
    font-size: 18px;
    font-weight: 900;
    height: 100%;
    padding: 10px;
    width: 100%;
}

.card-info {
    color: #539e59;;
    font-size: 30px;
    font-weight: 100;
}

.card-main {
    box-shadow: 0 0 4px darkgrey;
    box-sizing: border-box;
    color: #222222;
    font-family: "Helvetica Neue", Helvetica, serif;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
}

.grid-recollection-container-chart {
    align-items: center;
    display: none;
    justify-content: center;
}

.project-details-container-card .grid-description-container-image {
    display: none;
}

.tooltip-margin { margin: 15px 0 0 10px }

@media (min-width: 1430px) {
    .grid-recollection-chart {
        width: 250px;
        height: auto;
    }

    .grid-main {
        display: inline-grid;
        grid-template-columns: repeat(14, 1fr);
        grid-template-rows: repeat(7, auto);
    }

    .grid-image {
        background: #5bb462 no-repeat center;
        background-size: cover;
        grid-column: 1 / 4;
        grid-row: 1 / -1;
        justify-self: stretch;
    }

    .grid-description-container-category {
        font-size: 18px;
        font-weight: 200;
        grid-column: 4 / 11;
        grid-row: 1;
        padding-left: 15px;
    }

    .grid-description-container-title {
        color: #5bb462;
        font-size: 35px;
        font-weight: 400;
        grid-column: 4 / 11;
        grid-row: 2;
        padding-left: 15px;
    }

    .grid-description-container-operated-by {
        font-size: 18px;
        grid-column: 4 / 10;
        grid-row: 3;
        padding-left: 15px;
    }

    .grid-description-container-location {
        color: #5bb462;
        font-size: 17px;
        grid-column: 4 / 8;
        grid-row: 4;
        padding-left: 15px;
        margin: 10px 0;
    }

    .project-details-container-card .grid-description-container-location {
        grid-column: 4 / 10;
    }

    .grid-description-container-data {
        font-size: 18px;
        font-weight: 200;
        grid-column: 4 / 11;
        grid-row: 5;
        padding-left: 15px;
        color: #777777;
    }

    .grid-description-container-image {
        background-size: contain;
        background-position: center;
        grid-column: 10 / 11;
        grid-row: 3 / 5;
        padding-left: 15px;
    }

    .grid-description-container-status {
        align-items: center;
        display: flex;
        font-size: 24px;
        font-weight: lighter;
        grid-column: 4 / 11;
        grid-row: 6;
        padding-left: 15px;
        margin-top: 10px;
    }

    .grid-description-container-status strong {
        color: #5bb462;
        font-weight: 400;
    }

    .grid-description-container-roi {
        grid-column: 4 / 6;
        grid-row: 7;
        padding-left: 15px;
    }

    .grid-description-container-irr {
        grid-column: 6 / 8;
        grid-row: 7;
        padding-left: 15px;
    }

    .grid-description-container-payback {
        grid-column: 8 / 10;
        grid-row: 7;
        padding-left: 15px;
    }

    .grid-description-container-details {
        grid-column: 10 / 11;
        grid-row: 7;
        justify-self: end;
        padding-left: 15px;
    }

    .grid-recollection-container-social {
        align-items: center;
        display: none;
        grid-column: 11 / 15;
        grid-row: 1 / 2;
        justify-content: flex-end;
        padding: 10px 20px;
    }

    .grid-recollection-container-social .card-icon {
        font-size: 15px;
        padding-left: 10px;
    }

    .grid-recollection-container-chart {
        grid-column: 11 / 15;
        grid-row: 2 / 7;
        padding: 5px 10px;
        place-self: stretch;
    }

    .grid-recollection-container-chart.editing {
        grid-column: 11 / 15;
        grid-row: 1 / 6;
        padding: 5px 10px;
        place-self: stretch;
    }

    .grid-recollection-container-days-left {
        grid-column: 11 / 15;
        grid-row: 7 / 8;
        text-align: center;
    }

    .grid-recollection-container-days-left.editing {
        grid-row: 6 / 7;
        padding-top: 7px !important;
    }
}

@media (max-width: 1430px) and (min-width: 800px) {
    .grid-recollection-chart {
        width: 180px;
        height: auto;
    }

    .card-main {
        padding: 10px;
    }

    .grid-main {
        display: inline-grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(13, auto);
        grid-column-gap: 10px;
        grid-row-gap: 10px;
    }

    .grid-description-container-category {
        font-size: 18px;
        font-weight: 200;
        grid-column: 1 / 12;
        grid-row: 1;
    }

    .grid-recollection-container-social {
        align-items: center;
        display: none;
        grid-column: 12 / 13;
        grid-row: 1;
        justify-content: flex-end;
        padding: 10px 20px;
    }

    .grid-description-container-title {
        color: #5bb462;
        font-size: 25px;
        font-weight: 400;
        grid-column: 1 / 11;
        grid-row: 2;
    }

    .project-details-container-card .grid-description-container-title {
        display: none;
    }

    .grid-image {
        background: #5bb462 no-repeat center;
        background-size: cover;
        grid-column: 1 / 5;
        grid-row: 3 / 10;
        justify-self: stretch;
    }

    .grid-description-container-operated-by {
        font-size: 18px;
        grid-column: 5 / 13;
        grid-row: 4;
    }

    .grid-description-container-image {
        background-size: contain;
        background-position: center;
        min-height: 60px;
        grid-column: 5 / 7;
        grid-row: 5;
    }

    .grid-description-container-location {
        color: #5bb462;
        font-size: 17px;
        grid-column: 5 / 13;
        grid-row: 6 / 7;
    }

    .grid-description-container-data {
        font-size: 18px;
        font-weight: 200;
        grid-column: 5 / 13;
        grid-row: 7 / 10;
        color: #777777;
    }

    .grid-description-container-status {
        font-size: 24px;
        font-weight: lighter;
        grid-column: 1 / 7;
        grid-row: 10 / 11;
    }

    .grid-description-container-status strong {
        color: #5bb462;
        font-weight: 400;
    }

    .grid-description-container-roi {
        grid-column: 1 / 4;
        grid-row: 11;
    }

    .grid-description-container-irr {
        grid-column: 4 / 7;
        grid-row: 11;
    }

    .grid-description-container-payback {
        grid-column: 7 / 10;
        grid-row: 11;
    }

    .grid-description-container-details {
        grid-column: 1 / 6;
        grid-row: 12;
        justify-self: flex-start;
    }

    .grid-recollection-container-social .card-icon {
        font-size: 15px;
        padding-left: 10px;
    }

    .grid-recollection-container-chart {
        grid-column: 10 / 13;
        grid-row: 10 / 13;
    }

    .grid-recollection-container-days-left {
        grid-column: 7 / 10;
        grid-row: 12;
        justify-self: flex-start;
        text-align: left;
    }

    .grid-recollection-container-days-left.editing {
        grid-row: 10 / 13;
        padding-top: 7px !important;
    }
}

@media (max-width: 800px) {
    .card-info {
        color: #539e59;;
        font-size:26px;
        font-weight: 100;
    }

    .grid-recollection-chart {
        width: 200px;
        height: auto;
    }

    .card-container {
        padding: 5px;
    }

    .grid-main {
        display: inline-grid;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(16, auto);
    }

    .grid-image {
        background: #5bb462 no-repeat center;
        background-size: cover;
        grid-column: 1 / 13;
        grid-row: 1 / 5;
        justify-self: stretch;
        height: 15em;
    }

    .grid-description-container-category {
        font-size: 18px;
        font-weight: 200;
        grid-column: 1 / 12;
        grid-row: 6;
        padding: 5px 15px;
    }

    .grid-recollection-container-social {
        align-items: center;
        display: none;
        grid-column: 12 / 13;
        grid-row: 5;
        justify-content: flex-end;
        padding: 10px 20px;
    }

    .grid-recollection-container-social .card-icon {
        font-size: 15px;
        padding-left: 10px;
    }

    .grid-description-container-title {
        color: #5bb462;
        font-size: 25px;
        font-weight: 400;
        grid-column: 1 / 13;
        grid-row: 7;
        padding: 5px 15px;
    }

    .grid-description-container-operated-by {
        font-size: 18px;
        grid-column: 1 / 13;
        grid-row: 8;
        padding: 5px 15px;
    }

    .grid-description-container-image {
        background-size: contain;
        background-position: center;
        min-height: 60px;
        grid-column: 1 / 6;
        grid-row: 9;
        padding: 5px 15px;
    }

    .grid-description-container-location {
        color: #5bb462;
        font-size: 17px;
        grid-column: 1 / 13;
        grid-row: 10;
        padding: 5px 15px;
    }

    .grid-description-container-data {
        font-size: 18px;
        font-weight: 200;
        grid-column: 1 / 13;
        grid-row: 11;
        padding: 5px 15px;
        color: #777777;
    }

    .grid-description-container-status {
        font-size: 24px;
        font-weight: lighter;
        grid-column: 1 / 13;
        grid-row: 12;
        padding: 5px 15px;
    }

    .grid-description-container-status strong {
        color: #5bb462;
        font-weight: 400;
    }

    .grid-description-container-roi {
        grid-column: 1 / 5;
        grid-row: 13;
        padding: 5px 15px;
    }

    .grid-description-container-irr {
        grid-column: 5 / 9;
        grid-row: 13;
        padding: 5px 15px;
    }

    .grid-description-container-payback {
        grid-column: 9 / 13;
        grid-row: 13;
        padding: 5px 15px;
    }

    .grid-description-container-details {
        grid-column: 1 / 13;
        grid-row: 14;
        justify-self: center;
        padding: 10px 20px;
    }

    .grid-recollection-container-chart {
        grid-column: 1 / 13;
        grid-row: 15;
        padding: 0 10px;
    }

    .grid-recollection-container-days-left {
        grid-column: 1 / 13;
        grid-row: 16;
        justify-self: center;
        text-align: center;
    }
}
