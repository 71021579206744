.invest-button .btn-primary {
    background-color: #5bb462;
    border: none;
    font-size: 15px;
    text-transform: uppercase;
    width: 55%;
}

.invest-button .btn-primary:hover {
    background-color: #71388e;
}

.invest-button .btn-primary:active {
    background-color: #331f43;
}

.number-of-days {
    color: #5bb462;
    font-weight: bold;
}

.project-details-container-card-detail-title {
    color: #5bb462;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    text-transform: uppercase;
}

.project-details-container-card-detail-info {
    font-size: 15px;
    font-weight: 200;
    text-transform: capitalize;
}

/*.project-details-container-card {
    border-radius: 5px;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, auto);
    justify-items: stretch;
}*/

.project-details-container-card-info.watch {
    color: #5bb462;
    display: flex;
    font-size: 15px;
    grid-column: -2;
    grid-row: 1;
    justify-content: flex-end;
    margin: 10px 20px;
}

.project-details-container-card-info.image {
    background-color: #5bb462;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    grid-column: 1 / 4;
    grid-row: 1 / 9;
}

.project-details-container-card-info.category {
    grid-column: 4 / 10;
    grid-row: 2;
}

.project-details-container-card-info.operator {
    grid-column: 4 / 10;
    grid-row: 3;
}

.project-details-container-card-info.location {
    grid-column: 4 / 10;
    grid-row: 4;
}

.project-details-container-card-info.roi {
    grid-column: 4 / 6;
    grid-row: 5;
}

.project-details-container-card-info.roi .project-details-container-card-detail-title{
    text-transform: lowercase;
}

.project-details-container-card-info.ror {
    grid-column: 6;
    grid-row: 5;
}

.project-details-container-card-info.irr {
    grid-column: 7;
    grid-row: 5;
}

.project-details-container-card-info.payback {
    grid-column: 8 / 10;
    grid-row: 5;
}

.project-details-container-card-info.status {
    grid-column: 4 / 10;
    grid-row: 6;
}

.project-details-container-card-info.chart {
    grid-column: 10 / -1;
    grid-row: 2 / 5;
    height: 100%;
    padding: 0 10px;
    width: 100%;
}

.project-details-container-card-info.days-left {
    align-items: center;
    display: flex;
    font-size: 20px;
    grid-column: 10 / -1;
    grid-row: 5;
    justify-content: center;
}

.project-details-container-card-info.days-left .grid-recollection-container-days-left{
    text-align: center;
}

.project-details-container-card-info.invest-button {
    align-items: center;
    display: flex;
    grid-column: 10 / -1;
    grid-row: 6;
    justify-content: center;
}
