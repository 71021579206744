/* Arrows */
.slick-prev,
.slick-next {
    z-index: 1000;
    width: 60px;
    height: 60px;
}

.slick-prev:before,
.slick-next:before {
    font-size: 60px;
    line-height: 1;

    opacity: 1;
    color: #5bb462;

    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1px;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: 10px;
}

/* Dots */
.slick-slider {
    margin-bottom: 50px;
}

.slick-dots {
    position: absolute;
    bottom: 0;

    width: 100%;
    padding: 0;
    margin-bottom: -35px;

    list-style: none;

    text-align: center;
}

.slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 1px;
    padding: 0;

    cursor: pointer;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
    opacity: 1;
    color: #000000;;
}

.slick-dots li button:before {
    font-size: 10px;
}

.slick-dots li.slick-active button:before {
    opacity: 1;
    color: #000000;
}

@media (max-width: 600px) {
    .slick-slider {
        margin-bottom: 60px;
    }

    .slick-dots {
        position: absolute;
        bottom: 0;

        width: 100%;
        padding: 0;
        margin-bottom: -45px;

        list-style: none;

        text-align: center;
    }
}