.past-investment-card-container {
    background-color: transparent;
}

.past-investment-card {
    color: #222222;
    background-size: cover;
    background: url("./past-investments-card-bg.png") no-repeat center;
    display: flex;
    flex-direction: column;
    font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
    height: 750px;
    margin: 20px;
    width: 430px;
}

.past-investment-card-image {
    align-items: center;
    background-size: cover;
    background: #5bb462 no-repeat center;
    background-size: 100%;
    display: flex;
    height: 230px;
    justify-content: center;
    width: 100%;
}

.past-investment-card-data {
    padding: 20px;
}

.past-investment-card-title {
    color: #5bb462;
    font-size: 30px;
}

.past-investment-card-video-button {
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 400;
    padding: 20px 0;
}

.past-investment-card-video-button:hover {
    cursor: pointer;
}

.past-investment-card-video-button .icon {
    font-size: 40px;
    font-weight: 400;
}

.past-investment-card-status {
    font-size: 24px;
}

.past-investment-card-footer {
    order: 100;
    align-content: center;
    background-color: #5bb462;
    color: #ffffff;
    display: flex;
    font-size: 18px;
    justify-content: center;
    margin-top: auto;
    padding: 20px;
    width: 100%;
}

.past-investment-card-footer:hover {
    cursor: pointer;
    background-color: #52a058;
}

@media (max-width: 600px) {
    .past-investment-card {
        width: 300px;
    }

    .past-investment-card-title {
        font-size: 25px;
    }
}