.offerings-header-grid {
    align-items: center;
    color: #5bb462;
    font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: normal !important;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 100%;
}

.offerings-header-title {
    font-size: 34px;
    grid-column: 1 / 4;
    grid-row: 1;
}

.offerings-header-type-selector {
    grid-column: 4 / 8;
    grid-row: 1;
}

.offerings-header-regulation-selector {
    grid-column: 8 / 11;
    grid-row: 1;
}

.offerings-header-active-check {
    align-content: center;
    display: flex;
    grid-column: 11;
    grid-row: 1;
    justify-content: flex-end;
    margin: 0 !important;
}

.offerings-header-past-check {
    align-content: center;
    display: flex;
    grid-column: 12;
    grid-row: 1;
    justify-content: flex-end;
    margin: 0 !important;
}

.offerings-header-regulation-selector button,
.offerings-header-type-selector button {
    background-color: #5bb462;
    width: 100%;
}

.offerings-header-regulation-selector button:hover,
.offerings-header-type-selector button:hover {
    background-color: #5bb462 !important;
}

.offerings-header-regulation-selector.show button,
.offerings-header-type-selector.show button {
    background-color:#52a058 !important;
}

.offerings-header-checkbox {
    -webkit-appearance: none;
    background-color: transparent;
    box-shadow: none;
    border: none;
    display: none;
    position: relative !important;
    left: 15px !important;
}

.offerings-header-checkbox + label:before {
    border: none;
    border-radius: 5px;
    background-color: #cacece;
    color: transparent;
    content: '\2713';
    font-size: 18px;
    margin-right: 3px;
    padding: 3px;
}

.offerings-header-checkbox:checked + label:before {
    background-color: #5bb462;
    color: white;
    font-size: 18px;
}

@media (max-width: 1395px) {
    .offerings-header-grid {
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: repeat(2, auto);
    }

    .offerings-header-title {
        font-size: 30px;
        grid-column: 1 / 7;
        grid-row: 1;
    }

    .offerings-header-type-selector {
        grid-column: 7 / 13;
        grid-row: 2;
    }

    .offerings-header-regulation-selector {
        grid-column: 1 / 7;
        grid-row: 2;
    }

    .offerings-header-active-check {
        align-content: center;
        display: flex;
        grid-column: 11;
        grid-row: 1;
        justify-content: flex-end;
        margin: 0 !important;
    }

    .offerings-header-past-check {
        align-content: center;
        display: flex;
        grid-column: 12;
        grid-row: 1;
        justify-content: flex-end;
        margin: 0 !important;
    }
}


@media (max-width: 800px) {
    .offerings-header-grid {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: repeat(3, auto);
        grid-column-gap: 5px;
    }

    .offerings-header-title {
        font-size: 30px;
        grid-column: 1;
        grid-row: 1;
    }

    .offerings-header-type-selector {
        grid-column: 1 / 4;
        grid-row: 2;
    }

    .offerings-header-regulation-selector {
        grid-column: 1 / 4;
        grid-row: 3;
    }

    .offerings-header-active-check {
        align-content: center;
        display: flex;
        grid-column: 2;
        grid-row: 1;
        justify-content: flex-end;
        margin: 0 !important;
    }

    .offerings-header-past-check {
        align-content: center;
        display: flex;
        grid-column: 3;
        grid-row: 1;
        justify-content: flex-end;
        margin: 0 !important;
    }

    @media (max-width: 500px) {
        .offerings-header-grid {
            grid-template-columns: repeat(2, auto);
            grid-template-rows: repeat(4, auto);
            grid-row-gap: 10px;
        }

        .offerings-header-title {
            grid-column: 1 / 3;
            grid-row: 1;
        }

        .offerings-header-type-selector {
            grid-column: 1 / 3;
            grid-row: 3;
        }

        .offerings-header-regulation-selector {
            grid-column: 1 / 3;
            grid-row: 4;
        }

        .offerings-header-active-check {
            grid-column: 1;
            grid-row: 2;
            justify-content: flex-start;
        }

        .offerings-header-past-check {
            grid-column: 2;
            grid-row: 2;
            justify-content: flex-start;
        }

        .offerings-header-active-check {
            display: flex;
            align-items: start;
        }
    }
}
