
.manage-accounts .table-wrapper h3{font-size: 18px;margin-bottom: 18px;}
.manage-accounts .table-wrapper h4{font-size: 16px;}
.manage-accounts .table {margin: 20px 0}
.manage-accounts .table .img-wrapper{width:320px;padding:0;vertical-align: middle;}
.manage-accounts .table img {width:100%;margin:0;}
.manage-accounts .table td,.manage-accounts .table th{border: 1px solid #E7E8F2;padding:20px;vertical-align: middle;}
.manage-accounts .table td span{display: block;font-weight: bold;}
.manage-accounts .table td {font-weight: normal;}



/* My Account Settings Tab */
.my-account-tab .react-tabs{display: flex;  clear: both;padding: 0;}
.my-account-tab .react-tabs__tab{margin-top: -1px;}
.my-account-tab .react-tabs__tab-list {
    display: flex;flex-direction: column;width:220px;margin: 0;padding: 0;clear: both;border: 0; color:#182F3B;font-size: 16px;font-weight: 700;box-sizing: border-box;}
.my-account-tab .react-tabs__tab-list p{margin: 8px 0;}
.my-account-tab .react-tabs__tab{border: 1px solid #182F3B1A;border-bottom: 0;}
.my-account-tab .react-tabs__tab:first-child{border-radius: 5px 5px 0 0;}
.my-account-tab .react-tabs__tab:last-child{border-bottom: 1px solid #182F3B1A;border-radius:0 0 5px 5px}
.my-account-tab .react-tabs__tab--selected{border-left: 4px solid #5BB462;}
.my-account-tab .react-tabs__tab:focus{box-shadow: none;outline: none;}
.my-account-tab .react-tabs__tab-panel--selected {
    display: block;width: calc(100% - 220px);padding-left: 25px;}

    .my-account-tab .react-tabs__tab-panel--selected .header{border: 1px solid #182F3B1A;padding: 10px;
        width: 100%;display: flex;
        align-items: center;}
        .my-account-tab .react-tabs__tab-panel--selected .header h5, .my-account-tab .react-tabs__tab-panel--selected .header button{font-weight: bold;}
    .my-account-tab .react-tabs__tab-panel--selected .content.box{border: 1px solid #182F3B1A;padding: 10px;background: none;
        margin-top: -1px;border-radius: 0 0 5px 5px;height:auto;}
        .p20{padding:20px !important}
     .my-account-tab .table {margin: 10px 0}
     .my-account-tab .table td,.my-account-tab .table th{padding:10px;}
     .my-account-tab .table.entites td:first-child{width:70%}

     .my-account-tab .add-entity button{border: none;
        background: none !important;
        padding: 0 !important;
        color: #5BB462 !important;
        text-transform: none;}

        .my-account-tab .add-entity button:active{box-shadow: none;}
        .my-account-tab .add-entity button:hover{text-decoration: underline;}

        .my-account-tab .manage-accounts .right_col{padding:0 !important}

        .my-account-tab .bitcoin-wallet .text-green{color:#5BB462 !important}