.save-banner-button {
    background: white;
    box-shadow: 0 -15px 30px -10px rgba(0, 0, 0, .25);
    bottom: 0;
    display: block;
    margin-bottom: 0;
    padding: 20px;
    position: fixed;
    z-index: 3;
}

.save-banner-button-static {
    background: white;
    box-shadow: 0 -15px 30px -10px rgba(0, 0, 0, .25);
    display: block;
    margin-bottom: 10px;
    padding: 20px;
    position: relative;
}

.nav-md .save-banner-button {
    width: 85%;
}

.nav-sm .save-banner-button {
    width: calc(100% - 100px);
}

@media (max-width: 991px) {
    .nav-md .save-banner-button {
        width: 100%;
    }
}