/* nav overhaul */
.nav-md .app-main--container {
    display: grid;
    grid-template-columns: [sidebar-start] 16% [sidebar-end main-content-start] 84% [main-content-end];
    grid-template-rows: [header-start] auto [header-end main-content-start] auto [main-content-end footer-start] auto [footer-end];
}

.nav-md .app-main--header {
    grid-column: sidebar-start / main-content-end;
    grid-row: header-start / header-end;
    align-self: stretch;
}

.nav-md .app-main--side-menu {
    background-color: #ffffff;
    grid-column: sidebar-start / sidebar-end;
    grid-row: main-content-start / main-content-end;
    border-right:1px solid #ccc;
}

.nav-md .app-main--content {
    display: block;
    grid-column: main-content-start / main-content-end;
    grid-row: main-content-start / main-content-end;
    padding-top: 0px;
}

.app-main--footer {
    padding: 0;
}

.nav-md .app-main--footer {
    grid-row: footer-start / footer-end;
    grid-column: sidebar-start / main-content-end;
}

.nav-sm .app-main--container {
    display: grid;
    grid-template-columns: [sidebar-start] 100px [sidebar-end main-content-start] calc(100% - 100px) [main-content-end];
    grid-template-rows: [header-start] auto [header-end main-content-start] auto [main-content-end footer-start] auto [footer-end];
}

.nav-sm .app-main--header {
    grid-column: sidebar-start / main-content-end;
    grid-row: header-start / header-end;
    align-self: stretch;
}

.nav-sm .app-main--side-menu {
    background-color: #ffffff;
    grid-column: sidebar-start / sidebar-end;
    grid-row: main-content-start / main-content-end;
}

.nav-sm .app-main--content {
    display: block;
    grid-column: main-content-start / main-content-end;
    grid-row: main-content-start / main-content-end;
    padding-top: 15px;
}

.nav-sm .app-main--footer {
    grid-row: footer-start / footer-end;
    grid-column: sidebar-start / main-content-end;
}

@media (max-width: 991px) {
    .nav-md .app-main--content {
        background-color: #fff !important;
        display: block;
        grid-column: sidebar-start / main-content-end;
        grid-row: main-content-start / main-content-end;
    }
}
