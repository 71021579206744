
@media(min-width:768px){
    .documents .right-sec{
        padding-left:1.6% !important

    }

}

.documents .jambo_table td:nth-of-type(1) { width: 70%;word-break: break-all;}

.documents .jambo_table a{font-weight: 600;}

.my-account-tab.document-tab .react-tabs{display: flex;  clear: both;padding: 0;flex-direction: column;}
.my-account-tab.document-tab .react-tabs__tab{margin-top:0;bottom:-1px;color:#8f8e9a;padding: 14px 20px;border: 0;border-left: 1px solid #ccc;border-radius: 0;font-size: 15px;}
.my-account-tab.document-tab .react-tabs__tab-list {
    display: flex;flex-direction: row;align-items: center;min-width:220px;margin: 0;padding: 0;clear: both;border: 0; color:#182F3B;font-size: 16px;font-weight: 700;box-sizing: border-box;
    border-bottom: 1px solid #ccc;width: 100%;
}
    .my-account-tab.document-tab .react-tabs__tab-list .title{ font-weight: 700;font-size: 16px;padding: 0 10px;}
    .documents .react-tabs__tab-list p{margin: 0;}

.my-account-tab.document-tab .react-tabs__tab--selected{color: #5BB462;border-bottom: 0px solid #ccc;}
.my-account-tab.document-tab .react-tabs__tab--selected:focus{color: #5BB462;}
    .my-account-tab.document-tab .react-tabs__tab:last-child{border-right: 0px solid #ccc;border-radius: 0;}
    .my-account-tab.document-tab  .react-tabs__tab-list p{margin: 0;}
    .my-account-tab.document-tab  .react-tabs__tab--selected{background: transparent;}
    .my-account-tab.document-tab .react-tabs__tab:last-child{border-bottom: 0;}

    .my-account-tab.document-tab .react-tabs__tab-panel--selected{    width: 100%;
        padding-left: 10px;padding-top: 20px;}
        .my-account-tab.document-tab .react-tabs__tab:focus{bottom: -1px;}