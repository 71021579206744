.footer-copyright--background {
    color: #ffffff;
    background-color: #092736;
    margin: 0;
    padding: 20px 0;
}

.footer-copyright--figure {
    /* overflow: hidden;
    height: 53px;
    width: 45px; */
}

.footer-copyright--figure-image {
    max-height: 32px;
    margin-top: 8px;
    width: auto;
    max-width: 100%
}

.footer-copyright--link-img {
    color: #ffffff;
    font-size:24px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.footer-copyright--text {
    font-size: 13px;
    margin-top: 18px;
    font-style: italic;
    color:rgb(255 255 255 / 50%);
    text-align: center;
}

.padding-30px {
    padding-bottom: 30px;
    padding-top: 30px;
}

.footer-terms{text-align:left;}
.footer-terms a,.footer-terms span{color:rgb(255 255 255 / 50%)}
.footer-terms a:hover{color:rgb(255 255 255 / 100%)}
.footer-terms span{padding:0 10px}