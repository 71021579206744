.fund-overview {
    background-repeat: no-repeat;
    background-size: cover;
    height: 350px;
}
.wp-block-jetpack-layout-grid {
	display: block;
	float: left;
	width: 100%;
  margin-bottom: 30px;

}
.prj-update{margin-bottom: 10px;display: block;}
.prj-update h1{font-size:28px;font-weight: 700;margin-bottom: 35px;}
.prj-update h2{font-size:24px;font-weight:600;margin-bottom: 15px;}
.prj-update h1.title{margin-bottom:20px;}
.wp-block-jetpack-layout-grid h2{font-size:20px;font-weight: 700;margin:15px 0 10px 0;}
.wp-block-jetpack-layout-grid p{margin:0}
.wp-block-jetpack-layout-grid h2 + p{margin-bottom: 10px;}
 .wp-block-jetpack-layout-grid-column {
    width: calc(33.3333% - 20px);
    float: left;
    padding: 12px;
    border-radius: 6px;
   border: 1px solid #E7E8F2;
    display: flex;
    flex-direction: column;
    margin:0 10px;
    box-sizing: border-box;
}
 .wp-block-image img {
	max-width: 100%;
    max-height: 220px;
    min-height: 220px;
    border-radius: 6px;
    margin:0;
}
.prj-update.map-view img{max-width:60%;margin-top:0px;width:60%;}
.prj-update.map-view button.close{position: absolute;right: 0;z-index: 1;}
.prj-update.map-view td,.prj-update.map-view h3.title{font-size: 18px;}
.prj-update.map-view td,.prj-update.map-view td th{padding:5px 10px;background:#f2f1ef;text-align:right;font-size: 14px}
.prj-update.map-view table{border:0px solid #5BB462;  font-family: "Montserrat" !important;width: 100%}
.prj-update.map-view .modal-header {
    padding:5px 10px;
    border-bottom: 2px solid #5BB462; 
}
.prj-update.map-view .modal-body {
   
    padding:0;
}
.prj-update.map-view .grey-bg{background: #f2f1ef;}
.prj-update.map-view .read-doc a{ color: #182F3B}
.prj-update.map-view table td:nth-child(1){width:30%}
.prj-update.map-view table td:nth-child(2){width:20%}
.prj-update.map-view table td:nth-child(3){width:30%}
.prj-update.map-view table td:nth-child(4){width:20%}
.prj-update.map-view table td strong{font-weight:700;}


/* Well documents */
.prj-update.map-view .well-list ul{list-style: none;}
.prj-update.map-view .well-list ul a{cursor: pointer;}
.prj-update.map-view .well-list h4{line-height: 26px;margin:0;font-weight: bold;}
.prj-update.map-view .well-list li a:hover svg path {
  fill: #5BB462;
}
.prj-update.map-view .well-list li  svg,.prj-update.map-view .well-list li a svg{padding-right:5px}
.prj-update.map-view .well-list li a:focus, .prj-update.map-view .well-list li a:hover {
    text-decoration: none;
    
}
.prj-update.map-view .well-list li { float: left;  padding-right: 20px;margin-bottom: 15px;font-size: 16px;}
.prj-update.map-view .well-list li a,.prj-update.map-view .well-list li {display: flex;align-items: center; }
.prj-update.map-view .well-list li span{padding-right: 8px;}


.prj-update.map-view .well-list table td:nth-child(1){width:20%}
.prj-update.map-view .well-list table td:nth-child(2){width:80%}
.prj-update.map-view .well-list table {border:0}
.prj-update.map-view .well-list table td{background: #fff;text-align: left;}
.prj-update .welcome-user{background: #7AC968;color: #fff;padding: 10px 20px;margin-bottom:20px;}
.prj-update .welcome-user span{font-weight: 700;}

.clear{clear: both;}
.well-update h1 {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 35px;
}
.well-detail{margin-top:20px;margin-bottom: 35px;}
.well-detail table{width:100%;
    border: 1px solid #E7E8F2;    border-bottom: 0;
    border-left: 0;font-size: 14px;}
.well-detail table h3{font-size: 18px;padding-left: 20px;}
.well-detail table td{vertical-align: top;}
.well-detail table td:first-child{width:25.8%;border-bottom: 1px solid #E7E8F2;
    border-left: 1px solid #E7E8F2;}
.well-detail table img{
    margin: 0;
     height: auto;
     max-width: 100% !important;
     min-width: 100% !important;
     max-height: 180px;
  }
  .well-detail  .well-summary{padding:10px}
  .well-detail  .well-summary h4{font-size: 14px;}
  .well-detail  .well-summary li{float:left;padding-right:10px;}
  .well-detail  .well-summary img{width: 16px !important;height: 21px;
    max-width: initial !important;
    min-width: auto !important;}
    .well-detail table .w-tooltip img {
        width: auto !important;
        max-width: 18px !important;
        min-width: 18px !important;
    }
    .w-tooltip {
        position: relative;
        display: inline-block;
     
      }
      
      .w-tooltip .w-tooltiptext {
        visibility: hidden;
        width:auto;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        text-align: center;
        border-radius: 6px;
        padding: 5px 0;
      
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
      }
      
      .w-tooltip:hover .w-tooltiptext {
        visibility: visible;
        min-width: 200px;
        font-size: 14px;
        font-weight: normal;
        background: #fff;
        box-shadow: 0px 1px 5px #919191;
        border-radius: 0;
        text-align: left;
        padding: 10px;
      }
.well-content{display: flex;
    border: 1px solid #E7E8F2;
    border-right: 0;
    padding-left: 20px;
    border-bottom: 0;}
.well-content .left,.well-content .right{width: 50%;padding:6px 0}
.well-content .left{width: 35%;border-right: 1px solid #E7E8F2;}
.well-content .right {
    padding-left: 20px;
}
h3{font-weight: 700;}
.well-content span{display: block;font-weight: 700;}
.well-content:last-child{  border-bottom: 1px solid #E7E8F2;}

.well-video-wrapper{position: relative; clear:both;display: flex;
    width: 100%;}
.well-video-wrapper li {width: calc(33.3333% - 20px);
    float: left;
    margin: 0 10px;}
.well-video-wrapper h5{font-size: 16px;font-weight: 600;margin-bottom:5px;}
.well-video-wrapper .player-wrapper {
    position: relative;
 }
  .well-video-wrapper .reactplayer {
   
    max-width: 100% !important;
     height: 100% !important; 
    background: #000;
  }
  @media(min-width:1400px){ 
    .well-video-wrapper .player-wrapper  {
    height: 400px;
    }
}
@media(min-width:1200px){ 
    .well-video-wrapper .player-wrapper  {
    height:300px;
    }
}
@media(min-width:992px){   
 
     .w-tooltip:hover .w-tooltiptext {
 min-width:400px;
  }
  .well-list .left {
    width: 20%;
} 
}
@media(max-width:991px){
    .prj-update.map-view .well-list h4{margin-bottom: 10px;}
    .prj-update.map-view .well-list h4 br{display: none; }
    .well-detail table{width:100%; }
    .wp-block-jetpack-layout-grid-column {
        width: auto;
        padding-right: 15px;
    padding-left: 15px;
    margin-right: 1;
    margin-left: 1;
    }
    .well-video-wrapper li {
        width: -webkit-fill-available;
        padding-right: 15px;
        padding-left: 15px;
    }
}

@media(max-width:600px){

   .wp-block-image img{width: auto;
        margin: 0 auto;
        height: auto;
        min-height: auto;}
}