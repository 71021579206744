.invest-dashboard.earnings  .card-wrapper{ margin-bottom: 30px;
    max-height: 200px;
    overflow: auto;}
.invest-dashboard.earnings  .card{height: auto;overflow: initial;}
.invest-dashboard.earnings .card h3{margin:0;}
.invest-dashboard.earnings .card li {
    padding-bottom: 10px;
}
/* .invest-dashboard.earnings  .card li:first-child{width:36%}
.invest-dashboard.earnings .card li:nth-child(2){width:20%}
.invest-dashboard.earnings .card li:nth-child(3){width:14%} */


.invest-dashboard.earnings{background-repeat:no-repeat;background-size: cover;height:500px;}
.invest-dashboard.earnings .list-funds{display: flex;padding: 0;margin:0;white-space: nowrap;width: 100%;}
.invest-dashboard.earnings .list-funds .lt-sec{    border-right: 1px solid  #E7E8F2;display: flex;align-items: center;}
.invest-dashboard.earnings .list-funds .rt-sec{list-style: none;align-items: center;padding:0px;margin: 0;max-height: 200px;overflow: auto;white-space: nowrap;width: 100%;display: inline-flex;}
.invest-dashboard.earnings .list-funds span{padding:0 10px;font-weight: 700; font-size: 16px;}
.invest-dashboard.earnings .list-funds li{padding:10px 20px;border-right: 1px solid #ccc;;font-weight:700;font-size: 15px;cursor: pointer;color:#8f8e9a;}
.invest-dashboard.earnings .list-funds li.active{color:#5BB462;font-weight:700;}
.invest-dashboard.earnings .list-funds .rt-sec::-webkit-scrollbar {
   height:8px;
  
  }
  
  /* Track */
  .invest-dashboard.earnings .list-funds .rt-sec::-webkit-scrollbar-track {
    background: #cecece; 
  }
   
  /* Handle */
  .invest-dashboard.earnings .list-funds .rt-sec::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,.4); 
  }
  
  /* Handle on hover */
  .invest-dashboard.earnings .list-funds .rt-sec::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

.invest-dashboard.earnings .distributions h3{border-bottom: 1px solid #E7E8F2; margin: 0;padding: 10px;}
.invest-dashboard.earnings .distributions h3 span{font-size:12px;display: block;font-weight: 400;margin-top: 5px;line-height: 18px;}
.earnings-report {display: flex;}
.earnings-report ul{list-style: none;padding-left: 0;display: flex;width: 100%;    flex-wrap: wrap;}
.earnings-report h3{font-weight: bold;text-align: center;line-height: 22px;}
.earnings-report ul li {

    width: 25%;
}
.earnings-report ul li .list-box{margin: 20px;
    border: 1px solid #E7E8F2;
    padding: 20px;
   display: flex;
    flex-direction: column;align-items: center;border-radius:6px;}
    .earnings-report ul li .list-box a{font-weight: 600;}
    .earnings-report ul li .list-box:first-child{margin-left:0}
.earnings-report ul li img{max-height: 60px;margin: 0 0 20px 0;}


.earnings-report.other-doc ul{flex-direction: column;}
.earnings-report.other-doc ul li {
    width: 100%;
}
.allPWidth{width:20% !important}
.PWidth{width:36% !important}
.earnings-report.other-doc ul li .list-box{flex-direction: row;align-items: center;margin: 10px 0;}
.earnings-report.other-doc ul li .list-box:first-child{margin-top:0;}
.earnings-report.other-doc ul li .list-box h3{margin-top: 0;    margin-bottom: 5px;font-size: 16px;}
.earnings-report.other-doc ul li .list-box h3 br{display: none;}
.earnings-report.other-doc ul li img{margin-bottom: 0;min-width:66px;}
@media (max-width: 991px){
    .earnings-report ul li {

        width:50%;
    }
    .allPWidth{width:115px !important}
    .PWidth{width:115px !important}
    .InvestWidth{width:150px !important}

}
@media(max-width:375px){
  .padzero{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}