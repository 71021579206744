.donutchart-track {
    fill: transparent;
    /*stroke: #8D56B5;*/
    stroke: #c3c3c3;
    stroke-width: 26;
}

.donutchart-indicator {
    fill: transparent;
    stroke: #5bb462;
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray .3s ease;
}

.donutchart {
    margin: 0 auto;
    border-radius: 50%;
    display: block;
}

.donutchart-text {
    font-family: 'Arial';
    fill: #48245A;
}

.donutchart-text-val {
    font-size: 40px;
    font-weight: 600;
}

.donutchart-text-recollection-of {
    font-size: 14px;
    font-weight: bold;
}

.donutchart-text-label {
    font-size: 15px;
    font-weight: bold;
}

.donutchart-indicator-min {
    fill: transparent;
    /*stroke: #B18ECC;*/
    stroke: #d9d9d9;
    stroke-width: 26;
    stroke-dasharray: 0 10000;
    transition: stroke-dasharray .3s ease;
}