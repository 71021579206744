.upper-case-text {
    text-transform: uppercase;
}

.margin-10-px {
    margin-top: 10px;
}

.high-text {
    font-size: 24px;
    font-weight: 400;
}

.under-text {
    font-size: 18px;
    font-weight: lighter;
    margin-top: -10px;
}

.purple-text {
    color: #3c7f41 !important;
}

.nav-md .card-container {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.nav-sm .card-container {
    margin-left: 20px !important;
    margin-right: 20px !important;
}

.no-padding {
    padding: 0 !important;
}

.regular-text {
    font-size: 18px;
}

.heavy-text {
    font-weight: 600;
}

@media (max-width: 991px) {
    .high-text {
        font-size: 22px;
        font-weight: 400;
    }

    .nav-md .card-container {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .nav-sm .card-container {
        margin-left: 10px !important;
        margin-right: 0 !important;
    }
}
