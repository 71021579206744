.comment-item {
    border-radius: 5px;
    padding: 0 !important;
    margin-bottom: 10px;
}

.comment-action {
    margin: 5px;
}

.comment-date {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 10px;
    padding-top: 10px;
}

.comment-text {
    font-size: 19px;
    font-weight: 300;
}

.comment-replies-loading {
    text-align: center;
}

.comment-replies {
    padding-left: 10px;
}

.comment-replies-item {
    background-color: #f0f0f0;
    border-bottom: 3px solid #fff;
    border-left: 3px solid rgba(0, 0, 0, .5);
    padding: 12px 5px;
}
