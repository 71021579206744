.project-details-table-container {
    border: none;
    border-radius: 5px 5px 0 0;
}

.project-details-table-container > nav {
    background-color: #E9E9E9;
}

.project-details-table-container > nav > div > ul {
    margin: 0;
    text-transform: uppercase !important;
}

.project-details-table-container > nav > div > ul > li > a[role='button'] {
    color: #A3A3A3 !important;
}

.project-details-table-container > nav > div > ul > li.active {
    border-bottom: #5bb462 2px;
    background-color: transparent;
}

.project-details-table-container > nav > div > ul > li.active a[role='button'] {
    background-color: transparent;
    border-bottom: #5bb462 solid 5px;
    color: #5bb462 !important;
    padding: 10px 15px;
}

.navigation-button {
    align-items: center !important;
    display: flex !important;
    height: 57px;
}

.navigation-button button {
    margin: 0 !important;
}

.navigation-button > a[role='button'] {
    padding: 0 !important;
}

.project-details-table-body {
    padding: 20px 30px;
}