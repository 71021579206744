body {
    overflow-x: hidden;
}

.App {
    text-align: center;
}

.selected-table-row {
    background-color: rgba(81, 41, 101, 0.29) !important;
}

.progress {
    height: 20px;
    margin: 10px;
    width: 255px;
}

.progress-bar-success {
    background-color: #1cdad2;
}

.progress {
    margin: 0;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.translucid-dark-bg {
    background: rgba(0, 0, 0, .659);
}

.no-display {
    display: none !important;
}

.App-intro {
    font-size: large;
}

.padding-10px {
    padding: 10px;
}

.padding-20px {
    padding: 20px;
}

.padding-30px {
    padding: 30px;
}

.padding-40px {
    padding: 40px;
}

.paddingLeft-30px {
    padding-left: 30px;
}

.paddingRight-30px {
    padding-right: 30px;
}

.paddingBottom-30px {
    padding-bottom: 20px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.no-margin {
    margin: 0 !important;
}

.red-text {
    color: red !important;
}

.black-text {
    color: black !important;
}

.dark-grey-text {
    color: #4a4a4a !important;
}

.center {
    flex: 1 !important;
    align-items: center !important;
    justify-content: center !important;
}

.no-text-wrap {
    white-space: nowrap;
}

.modal-date {
    position: absolute;
    right: 30px;
}

.bold-text {
    font-weight: bold !important;
}

.display-inline-flex {
    display: inline-flex;
}

.no-pointer, .no-pointer:hover {
    cursor: default;
}

.pointer {
    cursor: pointer;
}

.no-underline, .no-underline:hover {
    text-decoration: none !important;
}

.btn-dropdown {
    border: none;
    border-radius: 0;
    padding-top: 8px;
    padding-bottom: 5px;
    margin: 0;
}

.btn-dropdown:hover {
    color: black !important;
}

.btn-dropdown:active {
    color: black !important;
    text-shadow: white 0px 1px 1px;
}

.form-input {
    border: solid .5px #ccc !important;
    background-color: #fff !important;
    height: 34px !important;
    border-radius: 4px !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075) !important;
}

.form-input:disabled {
    background-color: #e3e3e3 !important;
}

.full-width {
    width: 100% !important;
}

.white-text-hover:hover {
    color: #fff !important;
}

.email-badge {
    background-color: #5bb462;
    border-radius: 3px;
    color: #fff;
    font-size: 15px;
    font-weight: normal;
    margin: 4px;
    padding: 10px;
}

.capitalize-text {
    text-transform: capitalize !important;
}

.dark-222-bg {
    background-color: #222222 !important;
}

.collapse-sidebar-big-screen {
    display: none;
}

.collapse-sidebar-small-screen {
    display: none;
}

.image-icon {
    margin: 0;
    height: 25px;
    padding-right: 5px;
}

.nav-sm .image-icon {
    height: 25px;
    margin: -10px 0 0;
}

.c-offering-detail-nav {
    max-width: 271px;
}

.t-uppercase {
    text-transform: uppercase
}

.u-margin-top-none {
    margin-top: 0
}

.t-weight-normal {
    font-weight: 400
}

.u-margin-none {
    margin: 0
}

.c-btn--orange {
    color: #fff;
    text-decoration: none;
    text-shadow: 0 1px 0 #502964;
    background-color: #502964 !important;
    border-color: #502964 !important;
}

.c-btn:not(:last-child) {
    margin-right: 8px;
}

.c-btn__fixed-md {
    display: inline-block;
    width: 250px;
}

.c-btn {
    text-transform: uppercase;
    text-align: center;
    border-radius: 2px;
    font-size: 1.1em;
    padding: 6px 10px;
    cursor: pointer;

    border: 1px solid #666;
    background: #f7f7f7;
}

.c-btn {
    font-family: Heebo, HelveticaNeue-Light, "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none
}

.sidenav__item a,
.sidenav__item a:active,
.sidenav__item a:focus {
    color: #5e5e5e;
    border-left: 2px solid #fff;
    padding: 2px 12px !important;
    background-color: transparent !important
}

.sidenav__item a:hover {
    font-weight: 500;
    border-left-color: #444;
    background-color: transparent !important
}

.sidenav__item.active a,
.sidenav__item.active a:active,
.sidenav__item.active a:focus {
    color: #3bb249;
    border-left-color: #3bb249;
    font-weight: 500;
    background-color: transparent !important
}

.list-group-item {
    font-size: 16px !important;
}

.input {
    font-size: 16px;
    font-weight: 300 !important;
    height: 42px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    background-color: #fcfcfc !important;
}

.input:active, .input:hover, .input:focus {
    border: 1px solid #7e3499 !important;
}

.error-input, .error-input:active, .error-input:hover, .error-input:focus {
    border: dashed red 2px !important;
    background: #f080804d !important;
}

.input-label {
    font-size: 18px !important;
    font-weight: 500 !important;
    text-transform: capitalize;
}

.center-media-action-buttons {
    align-items: center;
    display: flex;
    height: 42px;
    justify-content: center;
}

.no-capitalization {
    text-transform: inherit !important;
}

._90vh-max-height {
    max-height: 90vh;
    overflow-x: auto;
}

input[type="text"], input[type="number"], input[type="password"], input[type="email"], textarea, select {
    border: none;
    font-size: 16px;
    height: 42px;
    padding: 10px;
    border-radius: 5px;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0);
    -webkit-transition: box-shadow 0.3s ease;
    transition: box-shadow 0.3s ease;
    background-color: #f2f2f2;
}

@media (min-width: 991px) {
    .collapse-sidebar-big-screen {
        display: inline-block;
        background-color:transparent;
        border-radius: 0px;
        margin-left: 0px;
        margin-top: 0;
        padding: 5px;
        position: relative;
        text-align: center;
        width: auto;
        z-index: 1;
        height: 57px;
    line-height: 57px;
    }
}

@media (max-width: 991px) {
    .collapse-sidebar-small-screen {
        display: inline-block;
        background-color: #222222;
        border-radius: 0px;
        margin-left: 0px;
        /* margin-top: -15px; */
        padding: 5px;
        position: absolute;
        text-align: center;
        width: auto;
        z-index: 1;
        height:28px;
    }
    .leftIcon{
    display: inherit !important;
    }
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important
    }

    .hidden-print {
        display: none !important
    }
}

.fontSize14 { font-size: 14px !important; }