.past-investment-container {
    box-sizing: border-box;
    color: #222222;
    background-color: #cacece !important;
    display: inline-block;
    font-size: 18px;
    font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
    font-weight: 200;
    /*width: 100%;*/
}

.nav-md .past-investment-container {
    width: 100%;
}

.nav-sm .past-investment-container {
    width: 100%;
}

.past-investment-header {
    background-color: #5bb462;
    color: #ffffff !important;
    display: flex;
    justify-content: center;
}

.past-investment-triangle {
    background-color: #ffffff;
    clip-path: polygon(0 0, 70px 0, 35px 35px);
    height: 50px;
    margin-top: -1px;
    position: absolute;
    width: 100px;
}

.past-investment-title {
    color: #ffffff !important;
    font-size: 40px !important;
    padding: 30px 0;
}
