/* Messages */
/* .message-wrap{margin-top:20px} */
.message-wrap,.mail-list{border: 1px solid #ccc !important}
.message-wrap{border:0 !important}
.message-wrap input,.message-wrap select{background-color: #fff !important;border: 1px solid #ccc !important;border-radius: 3px !important;height: 36px !important;font-size: 14px !important;padding: 5px !important;}
.message-wrap input[type="text"]:focus, .message-wrap  select:focus {

    box-shadow:none !important;
}
.message-wrap .has-search .form-control-feedback {
    right: initial;
    right: 0;
    color: #ccc;
    margin-top:8px;
}

.message-wrap .has-search .form-control {
    padding-right: 12px;
    padding-right: 34px !important;
}
.mail-list ul{margin:0;padding: 0;height:530px;overflow: auto;}
.mailh {height: 586px; overflow: auto}

.mail-list h3{font-size: 14px;font-weight: 600;margin-bottom:0px;margin-top: 0;}
.mail-list p{margin-bottom: 0;}
.mail-list li{list-style: none;border-bottom: 1px solid #ccc;border-right: 1px solid #ccc;
    padding-top: 10px;padding-bottom: 10px;position: relative;cursor: pointer;}
.mail-list li .pl-2{padding-left: 10px;}
.mail-list .r-sec h3{font-size: 16px;}
.mail-list .r-sec .title{border-bottom: 2px solid #ccc;margin-top: 15px;}

.mail-list .active-list {
    background: #f0f0f0;
}
.send-msg img{margin:0}
.send-msg img + span{padding-left:5px;padding-top:2px}
.title-wrap{display: flex;
    align-items: center;
}

.title-wrap select,.title-wrap select:focus{background: none;border: 0;box-shadow: none;padding: 0;margin-top:2px;}