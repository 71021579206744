.card-button-container {
    align-content: center;
    padding: 30px 0;
    display: flex;
    justify-content: center;
}

.card-button-load-more {
    border: solid 1px #5bb462;
    color: #5bb462;
    font-size: 20px !important;
    padding: 20px 0;
    text-transform: uppercase !important;
    width: 250px;
}

.slick-track {
    margin: 0;
}
